import { useQuery } from '@apollo/client';
import { REFETCH_LANGUAGE_TRANSLATIONS_QUERY_EVENT } from '@/storychief/components/DropdownLanguageLabel/constants';
import query from '@/storychief/components/DropdownLanguageLabel/graphql/queries';
import { useEmitEventEffect } from '@/storychief/hooks';

/**
 *
 * @param {object} options
 * @param {string} options.modelId
 * @param {string} options.modelType
 * @returns {{ data: object, loadingLanguageTranslations: boolean }}
 */
function useLanguageTranslationsQuery({ modelId, modelType }) {
  const {
    data: { data } = {},
    loading,
    refetch,
  } = useQuery(query[modelType], {
    variables: {
      id: modelId,
    },
  });

  useEmitEventEffect(() => refetch(), REFETCH_LANGUAGE_TRANSLATIONS_QUERY_EVENT);

  return { data, loadingLanguageTranslations: loading };
}

export default useLanguageTranslationsQuery;
