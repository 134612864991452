import { gql } from '@apollo/client';

const fragments = {
  translation: gql`
    fragment DropdownLanguageTranslationInterface on TranslationInterface {
      __typename
      id
      language
      source {
        __typename
        id
        language
        translations {
          __typename
          id
          language
        }
      }
      translations {
        __typename
        id
        language
      }
    }
  `,
  contentItem: gql`
    fragment DropdownLanguageContentItemInterface on ContentItemInterface {
      __typename
      id
      language
      can {
        __typename
        delete
        duplicate
        update
        campaigns
        share
      }
    }
  `,
  story: gql`
    fragment DropdownLanguageStoryInterface on Story {
      __typename
      id
      status
      language
      can {
        edit
      }
    }
  `,
};

export default fragments;
