import { gql, useMutation } from '@apollo/client';
import { ErrorsMiddleware } from '@/graphql/middlewares';
import ContentItemMiddleware from '@/graphql/middlewares/ContentItemMiddleware';
import fragments from '@/storychief/components/DropdownLanguageLabel/graphql/fragments';

const UPDATE_LANGUAGE_MUTATION = gql`
  mutation UpdateContentLanguage($input: UpdateContentLanguageInput!) {
    updateContentLanguage(input: $input) {
      ...DropdownLanguageTranslationInterface
    }
  }
  ${fragments.translation}
`;

/**
 *
 * @param {object} options
 * @param {string} options.modelId
 * @param {string} options.modelType
 * @param {Array} [options.refetchQueries=[]]
 * @param {Function} options.onCompleted
 * @returns {{updateLanguage: Function, loadingUpdateLanguage: boolean}}
 */
function useLanguageTranslationsMutation({ modelId, modelType, refetchQueries = [], onCompleted }) {
  const [updateLanguage, { loading: loadingUpdateLanguage }] = useMutation(
    UPDATE_LANGUAGE_MUTATION,
    {
      context: {
        middlewares: [new ContentItemMiddleware(modelType, modelId), new ErrorsMiddleware()],
      },
      onError() {
        // Handled
      },
      refetchQueries,
      onCompleted() {
        if (onCompleted) {
          onCompleted();
        }
      },
    },
  );

  return { updateLanguage, loadingUpdateLanguage };
}

export default useLanguageTranslationsMutation;
