import { gql } from '@apollo/client';
import { CONTENT_MODEL_TYPES } from '@/contentTypes/constants/constants';
import fragments from '@/storychief/components/DropdownLanguageLabel/graphql/fragments';

const query = {
  Story: gql`
    query DropdownLanguageTranslateStory($id: ID!) {
      data: story(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageStoryInterface
      }
    }
    ${fragments.translation}
    ${fragments.story}
  `,
  [CONTENT_MODEL_TYPES.websitecontents]: gql`
    query DropdownLanguageTranslateWebsiteContent($id: ID!) {
      data: websiteContent(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
  [CONTENT_MODEL_TYPES.newsletters]: gql`
    query DropdownLanguageTranslateNewsletter($id: ID!) {
      data: newsletter(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
  [CONTENT_MODEL_TYPES.videoprojects]: gql`
    query DropdownLanguageTranslateVideoProject($id: ID!) {
      data: videoProject(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
  [CONTENT_MODEL_TYPES.webinars]: gql`
    query DropdownLanguageTranslateWebinar($id: ID!) {
      data: webinar(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
  [CONTENT_MODEL_TYPES.podcasts]: gql`
    query DropdownLanguageTranslatePodcast($id: ID!) {
      data: podcast(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
  [CONTENT_MODEL_TYPES.ebooks]: gql`
    query DropdownLanguageTranslateEbook($id: ID!) {
      data: ebook(id: $id) {
        __typename
        id
        ...DropdownLanguageTranslationInterface
        ...DropdownLanguageContentItemInterface
      }
    }
    ${fragments.translation}
    ${fragments.contentItem}
  `,
};

export default query;
